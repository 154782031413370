import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { useTheme } from '@mui/material';
import { useThrottle, useWindowSize } from '@uidotdev/usehooks';

import { type ResumeData, ResumeTemplateKey } from '../../interface';
import Card from '../common/Card';
import TemplateDocument from './TemplateDocument';
import { PageBreakMarker } from './components/PageBreakMarker';

interface ResumeTemplateProps {
  resume: ResumeData;
  selectedTemplateKey: ResumeTemplateKey;
}

// TODO: move to constants
// print page sizes in pixels for a4, letter, etc
const pageSizes = {
  A3: {
    width: 1123,
    height: 1587,
  },
  A4: {
    width: 794,
    height: 1123,
  },
  Letter: {
    width: 816,
    height: 1056,
  },
};

const TemplatePreview = ({
  resume,
  selectedTemplateKey = ResumeTemplateKey.StandardLayoutA,
}: ResumeTemplateProps) => {
  const Document = useMemo(
    () => <TemplateDocument resume={resume} selectedTemplateKey={selectedTemplateKey} />,
    [resume, selectedTemplateKey],
  );

  const theme = useTheme();
  const templatePreviewRef = useRef<HTMLDivElement | null>(null);
  const SelectPageSize = 'Letter'; // TODO: make it dynamic, after BE support is added
  const [pageBreaksPx, setPageBreaksPx] = useState<number[]>([]);
  const throttledPageBreaks = useThrottle(pageBreaksPx, 300);
  const [transformPreviewWidth, setTransformPreviewWidth] = useState(0);
  const [previewHeight, setPreviewHeight] = useState(0);
  const [scale, setScale] = useState(1);

  // Get window size using useWindowSize
  const windowSize = useWindowSize();

  const zoomInOutRef = useRef<ReactZoomPanPinchRef | null>(null);

  const calculatePageBreaks = useCallback(() => {
    const PAGE_BOTTOM_MARGIN = 50; // TODO: extract out to a common place, also being used with useDownload

    // calculate page breaks based on the height of the template preview
    const templatePreviewHeight = templatePreviewRef.current?.scrollHeight || 0;
    const pageHeight = pageSizes[SelectPageSize].height - PAGE_BOTTOM_MARGIN;
    const pageBreaks = Math.ceil(templatePreviewHeight / pageHeight);

    setPreviewHeight((40 + templatePreviewHeight) * scale);
    // find the pixels on which page breaks should be added
    const pageBreaksPixels = Array.from(
      { length: pageBreaks },
      (_, index) => pageHeight * index,
    ).filter((breakPx) => breakPx !== 0); // remove the first page break at 0px

    return pageBreaksPixels;
  }, [SelectPageSize, scale]);

  const updateScale = useCallback(() => {
    const pageWidth = pageSizes[SelectPageSize].width;
    const newScale = 0.99 * (transformPreviewWidth / pageWidth);
    setScale(newScale > 1 ? 1 : newScale);
    // Apply the scale using the ref's `setTransform` method
    if (zoomInOutRef.current) {
      zoomInOutRef.current.setTransform(0, 0, newScale > 1 ? 1 : newScale, 0);
    }
  }, [transformPreviewWidth]);

  useEffect(() => {
    // Observe window size change and trigger updateScale
    updateScale();
    setPageBreaksPx(calculatePageBreaks());
  }, [windowSize.width, Document, updateScale, calculatePageBreaks]); // Whenever window width changes, update the scale

  useEffect(() => {
    const drawer = document.querySelector('.transform-preview-container');

    if (drawer) {
      const resizeObserver = new ResizeObserver(() => {
        setTransformPreviewWidth(drawer.clientWidth);
      });

      // Start observing the drawer element
      resizeObserver.observe(drawer);

      // Set the initial width
      setTransformPreviewWidth(drawer.clientWidth);

      // Cleanup on component unmount
      return () => {
        resizeObserver.disconnect();
      };
    }
    // Return a no-op function if the drawer is not found
    return () => {};
  }, []);

  return (
    <div className="transform-preview-container">
      <TransformWrapper
        limitToBounds={false}
        initialScale={0.7}
        minScale={0.4}
        maxScale={1}
        ref={zoomInOutRef}
        wheel={{
          disabled: true,
        }}
        panning={{
          disabled: true,
        }}
        pinch={{
          disabled: true,
        }}
        doubleClick={{
          disabled: true,
        }}
        initialPositionX={0}
        initialPositionY={0}
      >
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: previewHeight,
          }}
        >
          <Card
            sx={{
              position: 'relative',
              height: '100%',
              width: `${pageSizes[SelectPageSize].width}px`,
              maxWidth: `${pageSizes[SelectPageSize].width}px`,
              borderRadius: '8px',
              boxSizing: 'border-box',
              overflow: 'unset',
              [theme.breakpoints.down('sm')]: {
                border: 'none',
              },
            }}
            cardContentProps={{
              sx: {
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                gap: 2.5,
                [theme.breakpoints.down('sm')]: {
                  padding: 0,
                },
                '&:last-child': { paddingBottom: 2 },
              },
            }}
          >
            <div ref={templatePreviewRef} className="template-preview">
              {Document}
              {
                // add page breaks
                throttledPageBreaks.map((breakPx) => (
                  <PageBreakMarker key={breakPx} breakPx={breakPx} />
                ))
              }
            </div>
          </Card>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};

export default TemplatePreview;
